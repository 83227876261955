
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'users',
	components: {},
	setup() {
		return;
	}
});
